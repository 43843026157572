<template>
  <div>
    <b-card>
      <div class="d-lg-flex justify-content-between align-items-center">
        <h3 class="font-weight-bolder">
          {{ $t('userGroup') }}
        </h3>
        <div class="income-order-search order-search">
          <div class="anan-input search-input">
            <div class="anan-input__inner anan-input__inner--normal">
              <input
                v-model="filter"
                type="text"
                :placeholder="$t('search')"
                class="anan-input__input"
              >
              <div class="anan-input__suffix">
                <i class="anan-input__clear-btn anan-icon" />
                <i class="anan-input__suffix-icon anan-icon">
                  <i class="fal fa-search" />
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-link :to="{name: 'admin-user-type-create'}">
        <button
          type="button"
          class="anan-button--xl-large anan-button anan-button--primary anan-button--normal mt-1"
        >
          <span> {{ $t('addUserGroup') }} </span>
        </button>
      </router-link>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <b-table
          striped
          hover
          responsive
          :total-rows="totalRows"
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
        >
          <template #cell(number)="data">
            <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
              GUSR-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
            </b-link>
          </template>
          <!-- <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template> -->
          <template #cell(actions)="data">
            <feather-icon
              icon="Edit3Icon"
              class="mr-1 text-primary cursor-pointer"
              @click="EditType(data.item)"
            />

            <feather-icon
              class="text-danger cursor-pointer"
              icon="TrashIcon"
              @click="DeleteData(data.item)"
            />
          </template>
        </b-table>
      </b-overlay>
      <div class="demo-spacing-0 d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @input="getData()"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BCard, BLink, BPagination, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      isActive: 'all',
      totalRows: 0,
      filter: null,
      items: [],
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'name', label: this.$t('userGroupName'), sortable: true, thStyle: { width: '70%' }, thClass: 'text-center',
        },
        {
          key: 'active_user', label: this.$t('activeUse'), sortable: true, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('actions'), sortable: true, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData('all')
  },
  methods: {
    async getData(type) {
      this.showOver = true
      // this.isActive = type
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          type,
        }
        const { data: res } = await this.$http.get('/groupbyuser/list/show_all', { params })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-user-type-edit', params: { id: data._id } })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              ID: data._id,
            }
            this.$http.post('groupbyuser/list/delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

      <style lang="scss" scoped></style>
